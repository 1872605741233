import React from 'react';
import { ReactComponent as SpheronLogo } from '@spheron/ui-library/dist/assets/spheron-specific/spheron-logo.svg';
import LoaderStyles from '../../../styles/compute/spheron-loader.module.scss';

interface IProps {
  height?: number;
  width?: number;
}

const SpheronLoader = ({ height, width }: IProps) => {
  return (
    <div className={LoaderStyles.loader__screen}>
      <div className={LoaderStyles.loader__screen__logo}>
        <SpheronLogo
          width={width}
          height={height}
          className="text-logo-primary dark:text-dark-logo-primary"
        />
      </div>
    </div>
  );
};

SpheronLoader.defaultProps = {
  height: 400,
  width: 400,
};

export default SpheronLoader;
