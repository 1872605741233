import React, { useEffect } from 'react';
import { RainbowKitProvider, darkTheme } from '@rainbow-me/rainbowkit';
import { SkeletonTheme } from 'react-loading-skeleton';
import { WagmiConfig } from 'wagmi';
import { RouterProvider } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './App.scss';
import NotificationBanner from './components/Compute/NotificationBanner/notification-banner';
import usePageTracking from './hooks/usePageTracking';
import { RootState } from './redux/compute/store';
import '@rainbow-me/rainbowkit/styles.css';
import { wagmiConfig } from './config/rainbow-config';
import { chains } from './config/chains';
import GlobalStyle from './styles/compute/global.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Footer from './components/Compute/Footer';
import computeRouter from './router/Compute/browser-router';
import ErrorFallback from './pages/ComputeNew/Global/ErrorFallback';
import SignupCallback from './components/Compute/SignupCallback/signup-callback';

function ComputeApp() {
  const theme = useSelector((state: RootState) => state.theme.theme);
  useEffect(() => {
    async function requestPermission() {
      if (
        Notification.permission === 'default' ||
        Notification.permission === 'denied'
      ) {
        await Notification.requestPermission();
      }
    }
    requestPermission();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * For setting AB experiments variables
   */
  // useConstructor(() => {
  //   (window as any).abVars = {
  //     deploy: 1,
  //   };
  //   (window as any).gtag('event', 'optimize.callback', {
  //     callback: (value: any) => {
  //       (window as any).abVars.deploy = value;
  //     },
  //   });
  // });

  // useConstructor(() => {
  //   removeCommunityLocalVariables(true);
  //   if (isCommunityOrgRoute(window.location.pathname.split('/')[1])) {
  //     sessionStorage.setItem('communityFlow', 'true');
  //     sessionStorage.setItem(
  //       'communityOrgName',
  //       window.location.pathname.split('/')[1]
  //     );
  //     if (!localStorage.getItem('jwt-token')) {
  //       dispatch(setThemeRtk(ITheme.LIGHT));
  //       dispatch(
  //         getSelectedOrganisationByNameThunk(
  //           window.location.pathname.split('/')[1]
  //         )
  //       );
  //     }
  //   } else {
  //     // eslint-disable-next-line no-lonely-if
  //     if (sessionStorage.getItem('communityFlow') === 'true')
  //       localStorage.removeItem('selected-org');
  //     sessionStorage.removeItem('communityFlow');
  //   }
  // });

  usePageTracking();

  useEffect(() => {
    const handleWindowResize = () => {
      const newIsMobile = window.innerWidth < 1024;

      const body = document.querySelector('body');
      if (body) {
        if (newIsMobile) {
          body.classList.add(`${GlobalStyle.responsive__view}`);
        } else {
          body.classList.remove(`${GlobalStyle.responsive__view}`);
        }
      }
    };

    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  const jwtToken = localStorage.getItem('jwt-token');
  const defaultAppComponent = (
    <div>
      <SkeletonTheme
        baseColor={`${theme === 'dark' ? '#06152B' : '#ebebeb'}`}
        highlightColor={`${theme === 'dark' ? '#000' : '#787878'}`}
      >
        <SignupCallback />
        <RouterProvider
          fallbackElement={<ErrorFallback error="" resetError={() => {}} />}
          router={computeRouter}
        />
        <NotificationBanner />
      </SkeletonTheme>
      <Footer />
    </div>
  );

  if (!jwtToken) {
    return (
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider chains={chains} theme={darkTheme()}>
          {defaultAppComponent}
        </RainbowKitProvider>
      </WagmiConfig>
    );
  }
  return defaultAppComponent;
}

export default ComputeApp;
