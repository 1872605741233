import React from 'react';
import SpheronLogo from '@spheron/ui-library/dist/assets/spheron-specific/spheron-logo-light.svg';
import SpheronDarkLogo from '@spheron/ui-library/dist/assets/spheron-specific/spheron-logo-dark.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { LoginButton } from '@spheron/ui-library';
import { ReactComponent as Arrow } from '@spheron/ui-library/dist/assets/arrow-left-1.svg';
import { ReactComponent as GithubIcon } from '@spheron/ui-library/dist/assets/crypto-social/GitHub.svg';
import { ReactComponent as GoogleIcon } from '../../../../../assets/compute/icons/google-icon.svg';
import { isDarkTheme } from '../../../../../redux/compute/theme/theme.utils';
import { AppDispatch, RootState } from '../../../../../redux/compute/store';
import { toggleSignupCallbackLoadingRtk } from '../../../../../redux/compute/user/user.slice';

const AuthSignup = () => {
  const { search } = useLocation();
  const redirectUrl = new URLSearchParams(search).get('redirectUrl');
  const theme = useSelector((state: RootState) => state.theme.theme);

  const dispatchRtk = useDispatch<AppDispatch>();

  const signUpWithProvider = async (provider: string) => {
    dispatchRtk(toggleSignupCallbackLoadingRtk(true));
    const githubSignInUrl = `${window.location.origin}/signup/${provider}`;
    window.open(githubSignInUrl, '_blank');
  };

  return (
    <div className="flex flex-col justify-center items-center w-full h-[calc(100vh-68px)] relative">
      <div className="absolute left left-8 top-8">
        <Link to="https://spheron.network" className="pointer-events-auto">
          <span className="flex flex-row gap-x-1 items-center hover:underline text-base-link dark:text-dark-base-link">
            <Arrow className="w-5 h-5 text-base-icon dark:text-dark-base-icon" />
            <span>Return to spheron.network</span>
          </span>
        </Link>
      </div>
      <div
        className="p-[100px] flex flex-col gap-y-14 
        md:border border-base-border dark:border-dark-base-border rounded-lg"
      >
        <span className="flex flex-row justify-center items-center">
          <img
            src={
              theme === null || isDarkTheme(theme)
                ? SpheronDarkLogo
                : SpheronLogo
            }
            alt="spheron"
            width="183"
            height="64"
          />
        </span>
        <div className="flex flex-col items-center">
          <div className="flex flex-col gap-y-6">
            <LoginButton
              classname="max-[352px]:w-[calc(100vw-32px)] max-[352px]:mx-auto"
              handleClick={() => {
                signUpWithProvider('google');
              }}
              label="Continue with Google"
              socialIcon={<GoogleIcon className="h-6 w-6" />}
            />
            <LoginButton
              classname="max-[352px]:w-[calc(100vw-32px)] max-[352px]:mx-auto"
              handleClick={() => {
                signUpWithProvider('github');
              }}
              label="Continue with GitHub"
              socialIcon={<GithubIcon className="h-6 w-6" />}
            />
          </div>
        </div>
        <div
          className="flex flex-col gap-y-1 items-center justify-center text-base font-normal 
          text-base-para-text-color dark:text-dark-base-para-text-color"
        >
          <p
            className="text-center text-base-para-text-color 
             dark:text-dark-base-para-text-color text-base font-normal"
          >
            By continuing, you agree to <br className="block md:hidden" /> our{' '}
            <a
              href="./Spheron_Website_Terms_of_Use.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span
                className="hover:underline cursor-pointer
                  text-base-link dark:text-dark-base-link"
              >
                Terms of Use.
              </span>
            </a>
          </p>
          <p>
            Already have an account?{' '}
            <span>
              <Link
                to={`/login${redirectUrl ? `?redirectUrl=${redirectUrl}` : ''}`}
                className="pointer-events-auto"
              >
                <span
                  className="inline-block cursor-pointer 
                  hover:underline text-base-link dark:text-dark-base-link"
                >
                  Log In
                </span>
              </Link>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AuthSignup;
