import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../../../../../redux/compute/store';
import { isDarkTheme } from '../../../../../redux/compute/theme/theme.utils';
import Loader from '../../../../../components/Compute/Loaders/loader';
import { logoutUserRtk } from '../../../../../redux/compute/user/user.slice';

const AuthLogin = () => {
  const navigate = useNavigate();
  const dispatchRtk = useDispatch<AppDispatch>();

  const theme = useSelector((state: RootState) => state.theme.theme);

  useEffect(() => {
    const logout = async () => {
      await dispatchRtk(logoutUserRtk());
      // navigate('/login');
    };
    logout();
  }, [dispatchRtk, navigate]);

  return (
    <div
      className={`h-screen w-screen ${
        isDarkTheme(theme) && 'bg-feedback-default-bg'
      }`}
    >
      <Loader />
    </div>
  );
};

export default AuthLogin;
