// eslint-disable-next-line no-unused-vars
enum ChainType {
  // eslint-disable-next-line no-unused-vars
  TESTNET = 'testnet',
  // eslint-disable-next-line no-unused-vars
  MAINNET = 'mainnet',
}

export interface IConfig {
  urls: {
    API_URL: string;
    WEBSOCKET_URL: string;
    API_UPLOAD_URL: string;
    API_SSE_URL: string;
    STATUS_API_URL: string;
    IMAGE_NOT_FOUND: string;
    DEFAULT_DOMAIN: string;
  };
  imageSize: {
    UPLOAD_IMAGE_SIZE: number;
  };
  jsonSize: {
    UPLOAD_JSON_SIZE: number;
  };
  web3: {
    PROJECT_ID: string;
    CMC_KEY: string;
    REMOVAL_VERIFYING_MESSAGE: string;
    ENABLE_VERIFYING_MESSAGE: string;
    WITHDRAWAL_VERIFYING_MESSAGE: string;
    TOKEN_SLIPPAGE: number;
    matic: {
      SUBSCRIPTION_CONTRACT_ADDRESS: string;
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS: string;
      BICONOMY_KEY: string;
      DESUB_GRAPHQL_URL: string;
      CHAIN_ID: number;
      TYPE: ChainType;
    };
    arbitrum: {
      SUBSCRIPTION_CONTRACT_ADDRESS: string;
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS: string;
      BICONOMY_KEY: string;
      DESUB_GRAPHQL_URL: string;
      CHAIN_ID: number;
      TYPE: ChainType;
    };
    solana: {
      SUBSCRIPTION_CONTRACT_ADDRESS: string;
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS: string;
      BICONOMY_KEY: string;
      DESUB_GRAPHQL_URL: string;
      CHAIN_ID: number;
      TYPE: ChainType;
      SOLANA_NETWORK_NAME: string;
      SOLANA_RPC: string;
      SOLANA_TREASURY: string;
      TOKEN_ADDRESS: string;
      SET_HARDCODE_AMOUNT: boolean;
      HARDCODE_AMOUNT_MULTIPLIER: number;
      VERIFY_TRANSACTION_DELAY: number;
      AMOUNT_DECIMAL_PRECISION: number;
    };
    bsc: {
      SUBSCRIPTION_CONTRACT_ADDRESS: string;
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS: string;
      BICONOMY_KEY: string;
      DESUB_GRAPHQL_URL: string;
      CHAIN_ID: number;
      TYPE: ChainType;
    };
    optimism: {
      SUBSCRIPTION_CONTRACT_ADDRESS: string;
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS: string;
      BICONOMY_KEY: string;
      DESUB_GRAPHQL_URL: string;
      CHAIN_ID: number;
      TYPE: ChainType;
    };
    gnosis: {
      SUBSCRIPTION_CONTRACT_ADDRESS: string;
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS: string;
      BICONOMY_KEY: string;
      DESUB_GRAPHQL_URL: string;
      CHAIN_ID: number;
      TYPE: ChainType;
    };
    avalanche: {
      SUBSCRIPTION_CONTRACT_ADDRESS: string;
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS: string;
      BICONOMY_KEY: string;
      DESUB_GRAPHQL_URL: string;
      CHAIN_ID: number;
      TYPE: ChainType;
    };
    filecoin: {
      SUBSCRIPTION_CONTRACT_ADDRESS: string;
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS: string;
      BICONOMY_KEY: string;
      DESUB_GRAPHQL_URL: string;
      CHAIN_ID: number;
      TYPE: ChainType;
    };
    base: {
      SUBSCRIPTION_CONTRACT_ADDRESS: string;
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS: string;
      BICONOMY_KEY: string;
      DESUB_GRAPHQL_URL: string;
      CHAIN_ID: number;
      TYPE: ChainType;
    };
    mantle: {
      SUBSCRIPTION_CONTRACT_ADDRESS: string;
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS: string;
      BICONOMY_KEY: string;
      DESUB_GRAPHQL_URL: string;
      CHAIN_ID: number;
      TYPE: ChainType;
    };
    linea: {
      SUBSCRIPTION_CONTRACT_ADDRESS: string;
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS: string;
      BICONOMY_KEY: string;
      DESUB_GRAPHQL_URL: string;
      CHAIN_ID: number;
      TYPE: ChainType;
    };
    zksync: {
      SUBSCRIPTION_CONTRACT_ADDRESS: string;
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS: string;
      BICONOMY_KEY: string;
      DESUB_GRAPHQL_URL: string;
      CHAIN_ID: number;
      TYPE: ChainType;
    };
  };
  registry: {
    CONTRACT_ADDRESS: string;
    REGISTRY_SUBGRAPH_QUERY_URL: string;
    REGISTRY_NETWORK: number;
    REGISTRY_EXPLORER_LINK: string;
  };
  nft: {
    SIGNING_MESSAGE: string;
    CONTRACT_ADDRESS: string;
    NFT_GRAPHQL_URL: string;
  };
  arweave: {
    GATEWAY_URL: string;
  };
  skynet: {
    GATEWAY_URL: string;
    DATA_DOMAIN: string;
  };
  ipfs: {
    GATEWAY_URL: string;
  };
  akashToken: {
    microAKT: number;
    decimalRound: number;
    deployAktDebit: number;
  };
  notification: {
    length: number;
  };
  intercom: {
    APP_ID: string;
  };
  planPrice: {
    DEPLOYMENT_ENV: number;
  };
  dollarDecimalRound: {
    DOLLAR_DECIMAL_ROUND: number;
  };
  stripe: {
    PUBLIC_KEY: string;
  };
  referral: {
    MINIMUM_WITHDRAWAL_AMOUNT_IN_DOLLAR: number;
    REGISTRATION_LINK: string;
    RECAPTCHA_SITE_KEY: string;
  };
  masterOrg: {
    LOADING_STEPS_DURATION: number;
  };
  compute: {
    MINIMUM_DEPOSIT: number;
    DISABLE_NEW_INSTANCE: boolean;
  };
}

export const NODE_ENV: string = process.env.REACT_APP_STAGE || 'local';
export const ANNOUNCEMENT_DATA: string =
  process.env.REACT_APP_ANNOUNCEMENT_DATA ||
  // eslint-disable-next-line max-len
  `{"text":"We're currently in maintenance mode, so new deployments are temporarily disabled.","expiry":1735603200000,"type":"warning","link":"","link2":""}`;
export const MARKETING_DATA: string =
  process.env.REACT_APP_MARKETING_DATA ||
  // eslint-disable-next-line max-len
  `{"show":true, "data":{"title":"Introducing Avail Node in the Spheron Marketplace!","description":"Discover efficiency redefined with Avail Node in our Spheron Marketplace – your gateway to a new era of possibilities! 🚀🌐","buttonText":"Try Avail Node","template":"Avail%20Full%20Node","templateId":"65c38aaf3e8737ce70df2543","iframe":"https://www.youtube.com/embed/Nyf1YGy3xL8?si=hCdZGK4katlBy6vZ","img":"https://img.youtube.com/vi/wLhs35wuo34/maxresdefault.jpg","template":"Avail+Full+Node", "isGpu": true}}`;
export const VIDEO_TUTORIAL: string =
  process.env.REACT_APP_VIDEO_TUTORIAL ||
  // eslint-disable-next-line max-len
  `[{"id":1,"heading":"Deploy Avail Light Client","subHeading":"1:19 min","thumbnail":"https://img.youtube.com/vi/wLhs35wuo34/maxresdefault.jpg","iframe":"https://www.youtube.com/embed/wLhs35wuo34?si=kNGj_t2JUVcHQPum"},{"id":1,"heading":"Deploy Avail Light Client","subHeading":"1:19 min","thumbnail":"https://img.youtube.com/vi/wLhs35wuo34/maxresdefault.jpg","iframe":"https://www.youtube.com/embed/wLhs35wuo34?si=kNGj_t2JUVcHQPum"}]`;
export const SPHERON_RESOURCES: string =
  process.env.REACT_APP_SPHERON_RESOURCES ||
  // eslint-disable-next-line max-len
  `{"show": false, "data":[{"id":1,"heading":"Docs","subHeading":"Learn how to use spheron","link":"https://docs.spheron.network","iconPath":"https://spheron-resources-2dbb7b.spheron.app/document.svg"},{"id":2,"heading":"Join Community","subHeading":"Click here to join the latest discussions","link":"https://community.spheron.network","iconPath":"https://spheron-resources-2dbb7b.spheron.app/document.svg"},{"id":3,"heading":"Changelogs","subHeading":"Know the latest changes introduced to spheron","link":"https://spheron.network","iconPath":"https://spheron-resources-2dbb7b.spheron.app/document.svg"}]}`;
export const DISABLED_REGIONS =
  process.env.REACT_APP_DISABLED_REGIONS || '{"regions":["us-east","eu-west"]}';

export const DISABLED_APPS =
  process.env.REACT_APP_DISABLED_APPS || '{"apps":[""]}';

export const TOKEN_EXPIRY = process.env.TOKEN_EXPIRY || 7;

const development: IConfig = {
  urls: {
    API_URL: 'https://api-dev.spheron.network/v1',
    WEBSOCKET_URL: 'wss://api-dev.spheron.network',
    API_UPLOAD_URL: 'https://api-dev.spheron.network',
    API_SSE_URL: 'https://api-dev.spheron.network/subscribe',
    STATUS_API_URL: 'https://status.spheron.network/api/v2/status.json',
    DEFAULT_DOMAIN: 'argoapp.io',
    IMAGE_NOT_FOUND:
      'https://ik.imagekit.io/argo/default-not-fount_RVbgyZDh5Q.png',
  },
  imageSize: {
    UPLOAD_IMAGE_SIZE: 5 * 1024 * 1024,
  },
  jsonSize: {
    UPLOAD_JSON_SIZE: 1 * 1024 * 1024,
  },
  web3: {
    PROJECT_ID: 'caff53d8ca5738df1e0f0de17aed90d9',
    CMC_KEY: '0c5b25a6-4d37-4836-8b43-a6c575667cdd',
    REMOVAL_VERIFYING_MESSAGE:
      "I'm the owner of this wallet and want to remove it from the organization.",
    ENABLE_VERIFYING_MESSAGE:
      "I'm the owner of this wallet and want to attach it to the organization.",
    WITHDRAWAL_VERIFYING_MESSAGE:
      'I hereby verify that I am the owner of the address.',
    TOKEN_SLIPPAGE: 0.01,
    matic: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0xE38E3EdA708F78c9fe75c80Eb4973fFa0bF43700',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0xd6db9ef51b821940ca9b52506222121301df042c',
      BICONOMY_KEY: '3alJF6kb1.13c26d0a-4514-4f22-8f1f-6db5ed8a854b',
      DESUB_GRAPHQL_URL:
        'https://api.thegraph.com/subgraphs/name/argoapp-live/payments-polygon-testnet',
      CHAIN_ID: 80001,
      TYPE: ChainType.TESTNET,
    },
    arbitrum: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0xA0c5ba7d9eccbE59000e8E67654CA5d867C6b401',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0x270d622149F46fFc72A9fD5A8cF92D1FdE5AD5E0',
      BICONOMY_KEY: 'MeM3kTNlG.fdba3616-3e9f-47cb-bf6e-5ec9913e3e6f',
      DESUB_GRAPHQL_URL:
        'https://api.thegraph.com/subgraphs/name/argoapp-live/payments-arbitrum-testnet',
      CHAIN_ID: 421613,
      TYPE: ChainType.TESTNET,
    },
    mantle: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0xb2E91e89E0493Ad4F3462BD8A4Ac59892eeEf380',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0x270d622149F46fFc72A9fD5A8cF92D1FdE5AD5E0',
      BICONOMY_KEY: 'MeM3kTNlG.fdba3616-3e9f-47cb-bf6e-5ec9913e3e6f',
      DESUB_GRAPHQL_URL: '',
      CHAIN_ID: 5001,
      TYPE: ChainType.TESTNET,
    },
    solana: {
      SUBSCRIPTION_CONTRACT_ADDRESS: '',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS: '',
      BICONOMY_KEY: '',
      DESUB_GRAPHQL_URL: '',
      CHAIN_ID: 10500,
      TYPE: ChainType.TESTNET,
      SOLANA_NETWORK_NAME: 'Solana',
      SOLANA_RPC: 'https://api.devnet.solana.com/',
      SOLANA_TREASURY: '2jmzh7h6KrNJSnzZTFE2MFPSeqMAnT2wPi4UqZFCjBfz',
      TOKEN_ADDRESS: 'So11111111111111111111111111111111111111112',
      SET_HARDCODE_AMOUNT: true,
      HARDCODE_AMOUNT_MULTIPLIER: 0.1,
      VERIFY_TRANSACTION_DELAY: 20000,
      AMOUNT_DECIMAL_PRECISION: 2,
    },
    bsc: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0xbf9afB0e70d2c77a63cF21eE43CD108373089C0f',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0x703E1fdcb950EC5B33E208628f928A316c9F828F',
      BICONOMY_KEY: 'MeM3kTNlG.fdba3616-3e9f-47cb-bf6e-5ec9913e3e6f',
      DESUB_GRAPHQL_URL:
        'https://api.thegraph.com/subgraphs/name/argoapp-live/payments-bsc-testnet',
      CHAIN_ID: 97,
      TYPE: ChainType.TESTNET,
    },
    optimism: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0x816413630c39780677E1584D4a6DbAFd76b161F7',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0x96Ca5955F25648FB44C5D772BAdB4313000C66Fe',
      BICONOMY_KEY: 'MeM3kTNlG.fdba3616-3e9f-47cb-bf6e-5ec9913e3e6f',
      DESUB_GRAPHQL_URL:
        'https://api.thegraph.com/subgraphs/name/argoapp-live/payments-optimism-testnet',
      CHAIN_ID: 420,
      TYPE: ChainType.TESTNET,
    },
    gnosis: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0x87c798cccDd9E5af60d615Ea5E31f3c968473b68',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0x5090d5E98F20fFb0dC5aeFd0dC752cD899Be25b0',
      BICONOMY_KEY: 'MeM3kTNlG.fdba3616-3e9f-47cb-bf6e-5ec9913e3e6f',
      DESUB_GRAPHQL_URL:
        'https://api.thegraph.com/subgraphs/name/argoapp-live/payments-gnosis-mainnet',
      CHAIN_ID: 100,
      TYPE: ChainType.TESTNET,
    },
    avalanche: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0x87B49A9b928b06AA5782cB7ec495da8c507CaADC',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0x96Ca5955F25648FB44C5D772BAdB4313000C66Fe',
      BICONOMY_KEY: '4uznnaqdF.825c9b1b-2359-46f1-a5b0-bf5474f99bff',
      DESUB_GRAPHQL_URL:
        'https://api.thegraph.com/subgraphs/name/spheronfdn/spheron-avax-testnet',
      CHAIN_ID: 43113,
      TYPE: ChainType.TESTNET,
    },
    filecoin: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0xa3C6DFBee79d8f86494DBD7B5D943335bCF543f9',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0xA78b8e953B78b1D100cda10c7907b959edc6b021',
      BICONOMY_KEY: '4uznnaqdF.825c9b1b-2359-46f1-a5b0-bf5474f99bff',
      DESUB_GRAPHQL_URL: '',
      CHAIN_ID: 314159,
      TYPE: ChainType.TESTNET,
    },
    base: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0xd74C08f8ffDF88C807367813Ad64a960618f4dcC',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0xcF5F08686dBE94B88b93FB2E8E5482A7f432C892',
      BICONOMY_KEY: '4uznnaqdF.825c9b1b-2359-46f1-a5b0-bf5474f99bff',
      DESUB_GRAPHQL_URL:
        'https://api.studio.thegraph.com/query/10660/spheron-base-testnet/version/latest',
      CHAIN_ID: 84531,
      TYPE: ChainType.TESTNET,
    },
    linea: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0x816413630c39780677E1584D4a6DbAFd76b161F7',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0x96Ca5955F25648FB44C5D772BAdB4313000C66Fe',
      BICONOMY_KEY: '4uznnaqdF.825c9b1b-2359-46f1-a5b0-bf5474f99bff',
      DESUB_GRAPHQL_URL: '',
      CHAIN_ID: 59140,
      TYPE: ChainType.TESTNET,
    },
    zksync: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0x176751dC764cA75b4Db06A4E464cd4Eefc00129e',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0x8412Ab9512c574b483d65208C4bA77a86cC1a96E',
      BICONOMY_KEY: '4uznnaqdF.825c9b1b-2359-46f1-a5b0-bf5474f99bff',
      DESUB_GRAPHQL_URL:
        'https://api.studio.thegraph.com/query/10660/spheron-zksync-testnet/version/latest',
      CHAIN_ID: 280,
      TYPE: ChainType.TESTNET,
    },
  },
  registry: {
    CONTRACT_ADDRESS: '0x5F94D696E079019f5c1C7Dd483c7aAfc8441C3E8',
    REGISTRY_SUBGRAPH_QUERY_URL:
      'https://api.thegraph.com/subgraphs/name/argoapp-live/secureon',
    REGISTRY_NETWORK: 137,
    REGISTRY_EXPLORER_LINK: 'https://polygonscan.com/address/',
  },
  nft: {
    SIGNING_MESSAGE: "I'm signing this message",
    CONTRACT_ADDRESS: '0xB4f93d4E0eE66F4bF850120776e47994bdC33DaA',
    NFT_GRAPHQL_URL:
      'https://api.thegraph.com/subgraphs/name/rekpero/spheronnftlaunch',
  },
  arweave: {
    GATEWAY_URL: 'https://arweave.net/',
  },
  skynet: {
    GATEWAY_URL: 'https://siasky.net/',
    DATA_DOMAIN: 'dev.argoapp.net',
  },
  ipfs: {
    GATEWAY_URL: 'dev.sphn.link',
  },
  akashToken: {
    microAKT: 1000000,
    decimalRound: 3,
    deployAktDebit: 5,
  },
  notification: {
    length: 5,
  },
  intercom: {
    APP_ID: 'bl9h9m1z',
  },
  planPrice: {
    DEPLOYMENT_ENV: 5,
  },
  dollarDecimalRound: {
    DOLLAR_DECIMAL_ROUND: 2,
  },
  stripe: {
    PUBLIC_KEY:
      // eslint-disable-next-line max-len
      'pk_test_51MQy3GSFJddRcWmbnVfL8GVDuVqYV6uP0eL5UMpWZNC4kTE9aH5FtUw1OUDuc6cUPl3xzvVMxjSanU8fsE327Hva00WlmShVHE',
  },
  referral: {
    MINIMUM_WITHDRAWAL_AMOUNT_IN_DOLLAR: 0.1,
    REGISTRATION_LINK: 'https://b4t4v7fj3cd.typeform.com/referral',
    RECAPTCHA_SITE_KEY: '6LcPoHsoAAAAAKSBuq6dKw0TnibY3MQNCWienNjw',
  },
  masterOrg: {
    LOADING_STEPS_DURATION: 6000,
  },
  compute: {
    MINIMUM_DEPOSIT: 15,
    DISABLE_NEW_INSTANCE: false,
  },
};

const production: IConfig = {
  urls: {
    API_URL: 'https://api-v2.spheron.network/v1',
    WEBSOCKET_URL: 'wss://api-v2.spheron.network',
    API_UPLOAD_URL: 'https://api-v2.spheron.network',
    API_SSE_URL: 'https://api-v2.spheron.network/subscribe',
    STATUS_API_URL: 'https://status.spheron.network/api/v2/status.json',
    DEFAULT_DOMAIN: 'spheron.app',
    IMAGE_NOT_FOUND:
      'https://ik.imagekit.io/argo/default-not-fount_RVbgyZDh5Q.png',
  },
  imageSize: {
    UPLOAD_IMAGE_SIZE: 5 * 1024 * 1024,
  },
  jsonSize: {
    UPLOAD_JSON_SIZE: 1 * 1024 * 1024,
  },
  web3: {
    PROJECT_ID: 'caff53d8ca5738df1e0f0de17aed90d9',
    CMC_KEY: '0c5b25a6-4d37-4836-8b43-a6c575667cdd',
    REMOVAL_VERIFYING_MESSAGE:
      "I'm the owner of this wallet and want to remove it from the organization.",
    ENABLE_VERIFYING_MESSAGE:
      "I'm the owner of this wallet and want to attach it to the organization.",
    WITHDRAWAL_VERIFYING_MESSAGE:
      'I hereby verify that I am the owner of the address.',
    TOKEN_SLIPPAGE: 0.01,
    matic: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0x87B49A9b928b06AA5782cB7ec495da8c507CaADC',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0xcF5F08686dBE94B88b93FB2E8E5482A7f432C892',
      BICONOMY_KEY: '3alJF6kb1.13c26d0a-4514-4f22-8f1f-6db5ed8a854b',
      DESUB_GRAPHQL_URL:
        'https://api.studio.thegraph.com/query/10660/spheron-polygon-mainnet/version/latest',
      CHAIN_ID: 137,
      TYPE: ChainType.MAINNET,
    },
    arbitrum: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0x032666197A5d9329e717800FC90E8C951bA12290',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0x96Ca5955F25648FB44C5D772BAdB4313000C66Fe',
      BICONOMY_KEY: 'MeM3kTNlG.fdba3616-3e9f-47cb-bf6e-5ec9913e3e6f',
      DESUB_GRAPHQL_URL:
        'https://api.studio.thegraph.com/query/10660/spheron-arbitrum-mainnet/version/latest',
      CHAIN_ID: 42161,
      TYPE: ChainType.MAINNET,
    },
    mantle: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0x76e593392523243ACD38ceD87c2007F14483a86B',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0xB52329A11333462D192110357Be2da470B79B13e',
      BICONOMY_KEY: 'MeM3kTNlG.fdba3616-3e9f-47cb-bf6e-5ec9913e3e6f',
      DESUB_GRAPHQL_URL: '',
      CHAIN_ID: 5000,
      TYPE: ChainType.MAINNET,
    },
    solana: {
      SUBSCRIPTION_CONTRACT_ADDRESS: '',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS: '',
      BICONOMY_KEY: '',
      DESUB_GRAPHQL_URL: '',
      CHAIN_ID: 10000,
      TYPE: ChainType.MAINNET,
      SOLANA_NETWORK_NAME: 'Solana',
      SOLANA_RPC:
        'https://solana-mainnet.g.alchemy.com/v2/UF18Ke3hZP7f-5Zw6ysKxxzXi8WHDQQu',
      SOLANA_TREASURY: '2jmzh7h6KrNJSnzZTFE2MFPSeqMAnT2wPi4UqZFCjBfz',
      TOKEN_ADDRESS: 'So11111111111111111111111111111111111111112',
      SET_HARDCODE_AMOUNT: false,
      HARDCODE_AMOUNT_MULTIPLIER: 1,
      VERIFY_TRANSACTION_DELAY: 20000,
      AMOUNT_DECIMAL_PRECISION: 2,
    },
    bsc: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0x38D47237D955e2630a86EeB91c23ef0d993517Cc',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0x0eC5DC79E652D21E1FA9749b5e2459c87859311d',
      BICONOMY_KEY: 'MeM3kTNlG.fdba3616-3e9f-47cb-bf6e-5ec9913e3e6f',
      DESUB_GRAPHQL_URL:
        'https://api.studio.thegraph.com/query/10660/spheron-bsc-mainnet/version/latest',
      CHAIN_ID: 56,
      TYPE: ChainType.MAINNET,
    },
    optimism: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0x032666197A5d9329e717800FC90E8C951bA12290',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0x96Ca5955F25648FB44C5D772BAdB4313000C66Fe',
      BICONOMY_KEY: 'MeM3kTNlG.fdba3616-3e9f-47cb-bf6e-5ec9913e3e6f',
      DESUB_GRAPHQL_URL:
        'https://api.studio.thegraph.com/query/10660/spheron-optimism-mainnet/version/latest',
      CHAIN_ID: 10,
      TYPE: ChainType.MAINNET,
    },
    gnosis: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0x87c798cccDd9E5af60d615Ea5E31f3c968473b68',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0x5090d5E98F20fFb0dC5aeFd0dC752cD899Be25b0',
      BICONOMY_KEY: 'MeM3kTNlG.fdba3616-3e9f-47cb-bf6e-5ec9913e3e6f',
      DESUB_GRAPHQL_URL:
        'https://api.studio.thegraph.com/query/10660/spheron-gnosis-mainnet/version/latest',
      CHAIN_ID: 100,
      TYPE: ChainType.MAINNET,
    },
    avalanche: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0x816413630c39780677E1584D4a6DbAFd76b161F7',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0x96Ca5955F25648FB44C5D772BAdB4313000C66Fe',
      BICONOMY_KEY: 'UNC5c83qw.55c0dea7-88cd-4bb0-9716-59f6c269f5b7',
      DESUB_GRAPHQL_URL:
        'https://api.studio.thegraph.com/query/10660/spheron-avalanche-mainnet/version/latest',
      CHAIN_ID: 43114,
      TYPE: ChainType.MAINNET,
    },
    filecoin: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0x4F9337B261136E8c06ed4F543e28AbeEe08D889E',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0x006089929469ec0489a143C0d71f52C7d0201CCf',
      BICONOMY_KEY: 'UNC5c83qw.55c0dea7-88cd-4bb0-9716-59f6c269f5b7',
      DESUB_GRAPHQL_URL: '',
      CHAIN_ID: 314,
      TYPE: ChainType.MAINNET,
    },
    base: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0xB52329A11333462D192110357Be2da470B79B13e',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0x96Ca5955F25648FB44C5D772BAdB4313000C66Fe',
      BICONOMY_KEY: 'UNC5c83qw.55c0dea7-88cd-4bb0-9716-59f6c269f5b7',
      DESUB_GRAPHQL_URL:
        'https://api.studio.thegraph.com/query/10660/spheron-base-mainnet/version/latest',
      CHAIN_ID: 8453,
      TYPE: ChainType.MAINNET,
    },
    linea: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0x96Ca5955F25648FB44C5D772BAdB4313000C66Fe',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0x816413630c39780677E1584D4a6DbAFd76b161F7',
      BICONOMY_KEY: 'UNC5c83qw.55c0dea7-88cd-4bb0-9716-59f6c269f5b7',
      DESUB_GRAPHQL_URL: '',
      CHAIN_ID: 59144,
      TYPE: ChainType.MAINNET,
    },
    zksync: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0x8412Ab9512c574b483d65208C4bA77a86cC1a96E',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0x863E4F8c6BC2F68d5902EBda655b98728B6C545A',
      BICONOMY_KEY: 'UNC5c83qw.55c0dea7-88cd-4bb0-9716-59f6c269f5b7',
      DESUB_GRAPHQL_URL:
        'https://api.studio.thegraph.com/query/10660/spheron-payment-zksync/version/latest',
      CHAIN_ID: 324,
      TYPE: ChainType.MAINNET,
    },
  },
  registry: {
    CONTRACT_ADDRESS: '0x5F94D696E079019f5c1C7Dd483c7aAfc8441C3E8',
    REGISTRY_SUBGRAPH_QUERY_URL:
      'https://api.thegraph.com/subgraphs/name/argoapp-live/secureon',
    REGISTRY_NETWORK: 137,
    REGISTRY_EXPLORER_LINK: 'https://polygonscan.com/address/',
  },
  nft: {
    SIGNING_MESSAGE: "I'm signing this message",
    CONTRACT_ADDRESS: '0xB4f93d4E0eE66F4bF850120776e47994bdC33DaA',
    NFT_GRAPHQL_URL:
      'https://api.thegraph.com/subgraphs/name/rekpero/spheronnftlaunch',
  },
  arweave: {
    GATEWAY_URL: 'https://arweave.net/',
  },
  skynet: {
    GATEWAY_URL: 'https://siasky.net/',
    DATA_DOMAIN: 'app.argoapp.net',
  },
  ipfs: {
    GATEWAY_URL: 'sphn.link',
  },
  akashToken: {
    microAKT: 1000000,
    decimalRound: 3,
    deployAktDebit: 5,
  },
  notification: {
    length: 5,
  },
  intercom: {
    APP_ID: 'bl9h9m1z',
  },
  planPrice: {
    DEPLOYMENT_ENV: 5,
  },
  dollarDecimalRound: {
    DOLLAR_DECIMAL_ROUND: 2,
  },
  stripe: {
    PUBLIC_KEY:
      // eslint-disable-next-line max-len
      'pk_live_51NAvgxL7rDi2tBajNDIw3siIbDcaAziwBuJndlMlxuwESn2zckxcls35GYOZNoVd7Vam5icwEyAdLmmnvLwIqFk700JdlzAzCV',
  },
  referral: {
    MINIMUM_WITHDRAWAL_AMOUNT_IN_DOLLAR: 100,
    REGISTRATION_LINK: 'https://b4t4v7fj3cd.typeform.com/referral',
    RECAPTCHA_SITE_KEY: '6LcPoHsoAAAAAKSBuq6dKw0TnibY3MQNCWienNjw',
  },
  masterOrg: {
    LOADING_STEPS_DURATION: 6000,
  },
  compute: {
    MINIMUM_DEPOSIT: 15,
    DISABLE_NEW_INSTANCE: false,
  },
};

const local: IConfig = {
  urls: {
    API_URL: 'http://localhost:8080/v1',
    WEBSOCKET_URL: 'ws://localhost:8080',
    API_UPLOAD_URL: 'http://localhost:8002',
    API_SSE_URL: 'http://localhost:8080/subscribe',
    STATUS_API_URL: 'https://status.spheron.network/api/v2/status.json',
    DEFAULT_DOMAIN: 'argoapp.io',
    IMAGE_NOT_FOUND:
      'https://ik.imagekit.io/argo/default-not-fount_RVbgyZDh5Q.png',
  },
  imageSize: {
    UPLOAD_IMAGE_SIZE: 5 * 1024 * 1024,
  },
  jsonSize: {
    UPLOAD_JSON_SIZE: 1 * 1024 * 1024,
  },
  web3: {
    PROJECT_ID: 'caff53d8ca5738df1e0f0de17aed90d9',
    CMC_KEY: '0c5b25a6-4d37-4836-8b43-a6c575667cdd',
    REMOVAL_VERIFYING_MESSAGE:
      "I'm the owner of this wallet and want to remove it from the organization.",
    ENABLE_VERIFYING_MESSAGE:
      "I'm the owner of this wallet and want to attach it to the organization.",
    WITHDRAWAL_VERIFYING_MESSAGE:
      'I hereby verify that I am the owner of the address.',
    TOKEN_SLIPPAGE: 0.01,
    matic: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0xE38E3EdA708F78c9fe75c80Eb4973fFa0bF43700',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0xd6db9ef51b821940ca9b52506222121301df042c',
      BICONOMY_KEY: '3alJF6kb1.13c26d0a-4514-4f22-8f1f-6db5ed8a854b',
      DESUB_GRAPHQL_URL:
        'https://api.thegraph.com/subgraphs/name/argoapp-live/payments-polygon-testnet',
      CHAIN_ID: 80001,
      TYPE: ChainType.TESTNET,
    },
    arbitrum: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0xA0c5ba7d9eccbE59000e8E67654CA5d867C6b401',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0x270d622149F46fFc72A9fD5A8cF92D1FdE5AD5E0',
      BICONOMY_KEY: 'MeM3kTNlG.fdba3616-3e9f-47cb-bf6e-5ec9913e3e6f',
      DESUB_GRAPHQL_URL:
        'https://api.thegraph.com/subgraphs/name/argoapp-live/payments-arbitrum-testnet',
      CHAIN_ID: 421613,
      TYPE: ChainType.TESTNET,
    },
    mantle: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0xb2E91e89E0493Ad4F3462BD8A4Ac59892eeEf380',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0x270d622149F46fFc72A9fD5A8cF92D1FdE5AD5E0',
      BICONOMY_KEY: 'MeM3kTNlG.fdba3616-3e9f-47cb-bf6e-5ec9913e3e6f',
      DESUB_GRAPHQL_URL: '',
      CHAIN_ID: 5001,
      TYPE: ChainType.TESTNET,
    },
    solana: {
      SUBSCRIPTION_CONTRACT_ADDRESS: '',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS: '',
      BICONOMY_KEY: '',
      DESUB_GRAPHQL_URL: '',
      CHAIN_ID: 10500,
      TYPE: ChainType.TESTNET,
      SOLANA_NETWORK_NAME: 'Solana',
      SOLANA_RPC: 'https://api.devnet.solana.com/',
      SOLANA_TREASURY: '2jmzh7h6KrNJSnzZTFE2MFPSeqMAnT2wPi4UqZFCjBfz',
      TOKEN_ADDRESS: 'So11111111111111111111111111111111111111112',
      SET_HARDCODE_AMOUNT: true,
      HARDCODE_AMOUNT_MULTIPLIER: 0.1,
      VERIFY_TRANSACTION_DELAY: 20000,
      AMOUNT_DECIMAL_PRECISION: 2,
    },
    bsc: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0xbf9afB0e70d2c77a63cF21eE43CD108373089C0f',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0x703E1fdcb950EC5B33E208628f928A316c9F828F',
      BICONOMY_KEY: 'MeM3kTNlG.fdba3616-3e9f-47cb-bf6e-5ec9913e3e6f',
      DESUB_GRAPHQL_URL:
        'https://api.thegraph.com/subgraphs/name/argoapp-live/payments-bsc-testnet',
      CHAIN_ID: 97,
      TYPE: ChainType.TESTNET,
    },
    optimism: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0x816413630c39780677E1584D4a6DbAFd76b161F7',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0x96Ca5955F25648FB44C5D772BAdB4313000C66Fe',
      BICONOMY_KEY: 'MeM3kTNlG.fdba3616-3e9f-47cb-bf6e-5ec9913e3e6f',
      // NEEDS TO BE UPDATED
      DESUB_GRAPHQL_URL:
        'https://api.thegraph.com/subgraphs/name/argoapp-live/payments-optimism-testnet',
      CHAIN_ID: 420,
      TYPE: ChainType.TESTNET,
    },
    gnosis: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0x87c798cccDd9E5af60d615Ea5E31f3c968473b68',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0x5090d5E98F20fFb0dC5aeFd0dC752cD899Be25b0',
      BICONOMY_KEY: 'MeM3kTNlG.fdba3616-3e9f-47cb-bf6e-5ec9913e3e6f',
      DESUB_GRAPHQL_URL:
        'https://api.thegraph.com/subgraphs/name/argoapp-live/payments-gnosis-mainnet',
      CHAIN_ID: 100,
      TYPE: ChainType.TESTNET,
    },
    avalanche: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0x87B49A9b928b06AA5782cB7ec495da8c507CaADC',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0x96Ca5955F25648FB44C5D772BAdB4313000C66Fe',
      BICONOMY_KEY: '4uznnaqdF.825c9b1b-2359-46f1-a5b0-bf5474f99bff',
      // NEEDS TO BE UPDATED
      DESUB_GRAPHQL_URL:
        'https://api.thegraph.com/subgraphs/name/argoapp-live/payments-avalanche-testnet',
      CHAIN_ID: 43113,
      TYPE: ChainType.TESTNET,
    },
    filecoin: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0xa3C6DFBee79d8f86494DBD7B5D943335bCF543f9',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0xA78b8e953B78b1D100cda10c7907b959edc6b021',
      BICONOMY_KEY: '4uznnaqdF.825c9b1b-2359-46f1-a5b0-bf5474f99bff',
      DESUB_GRAPHQL_URL: '',
      CHAIN_ID: 314159,
      TYPE: ChainType.TESTNET,
    },
    base: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0xd74C08f8ffDF88C807367813Ad64a960618f4dcC',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0xcF5F08686dBE94B88b93FB2E8E5482A7f432C892',
      BICONOMY_KEY: '4uznnaqdF.825c9b1b-2359-46f1-a5b0-bf5474f99bff',
      DESUB_GRAPHQL_URL:
        'https://api.studio.thegraph.com/query/10660/spheron-base-testnet/version/latest',
      CHAIN_ID: 84531,
      TYPE: ChainType.TESTNET,
    },
    linea: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0x816413630c39780677E1584D4a6DbAFd76b161F7',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0x96Ca5955F25648FB44C5D772BAdB4313000C66Fe',
      BICONOMY_KEY: '4uznnaqdF.825c9b1b-2359-46f1-a5b0-bf5474f99bff',
      DESUB_GRAPHQL_URL: '',
      CHAIN_ID: 59140,
      TYPE: ChainType.TESTNET,
    },
    zksync: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0x176751dC764cA75b4Db06A4E464cd4Eefc00129e',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0x8412Ab9512c574b483d65208C4bA77a86cC1a96E',
      BICONOMY_KEY: '4uznnaqdF.825c9b1b-2359-46f1-a5b0-bf5474f99bff',
      DESUB_GRAPHQL_URL:
        'https://api.studio.thegraph.com/query/10660/spheron-zksync-testnet/version/latest',
      CHAIN_ID: 280,
      TYPE: ChainType.TESTNET,
    },
  },
  registry: {
    CONTRACT_ADDRESS: '0x5F94D696E079019f5c1C7Dd483c7aAfc8441C3E8',
    REGISTRY_SUBGRAPH_QUERY_URL:
      'https://api.thegraph.com/subgraphs/name/argoapp-live/secureon',
    REGISTRY_NETWORK: 137,
    REGISTRY_EXPLORER_LINK: 'https://polygonscan.com/address/',
  },
  nft: {
    SIGNING_MESSAGE: "I'm signing this message",
    CONTRACT_ADDRESS: '0xB4f93d4E0eE66F4bF850120776e47994bdC33DaA',
    NFT_GRAPHQL_URL:
      'https://api.thegraph.com/subgraphs/name/rekpero/spheronnftlaunch',
  },
  arweave: {
    GATEWAY_URL: 'https://arweave.net/',
  },
  skynet: {
    GATEWAY_URL: 'https://siasky.net/',
    DATA_DOMAIN: 'dev.argoapp.net',
  },
  ipfs: {
    GATEWAY_URL: 'dev.sphn.link',
  },
  akashToken: {
    microAKT: 1000000,
    decimalRound: 3,
    deployAktDebit: 5,
  },
  notification: {
    length: 5,
  },
  intercom: {
    APP_ID: 'bl9h9m1z',
  },
  planPrice: {
    DEPLOYMENT_ENV: 5,
  },
  dollarDecimalRound: {
    DOLLAR_DECIMAL_ROUND: 2,
  },
  stripe: {
    PUBLIC_KEY:
      // eslint-disable-next-line max-len
      'pk_test_51MQy3GSFJddRcWmbnVfL8GVDuVqYV6uP0eL5UMpWZNC4kTE9aH5FtUw1OUDuc6cUPl3xzvVMxjSanU8fsE327Hva00WlmShVHE',
  },
  referral: {
    MINIMUM_WITHDRAWAL_AMOUNT_IN_DOLLAR: 0.1,
    REGISTRATION_LINK: 'https://b4t4v7fj3cd.typeform.com/referral',
    RECAPTCHA_SITE_KEY: '6LcPoHsoAAAAAKSBuq6dKw0TnibY3MQNCWienNjw',
  },
  masterOrg: {
    LOADING_STEPS_DURATION: 6000,
  },
  compute: {
    MINIMUM_DEPOSIT: 15,
    DISABLE_NEW_INSTANCE: false,
  },
};

const tempdev: IConfig = {
  urls: {
    API_URL: 'https://temp-api-dev.spheron.network/v1',
    WEBSOCKET_URL: 'wss://temp-api-dev.spheron.network',
    API_UPLOAD_URL: 'https://temp-api-dev.spheron.network',
    API_SSE_URL: 'https://temp-api-dev.spheron.network/subscribe',
    STATUS_API_URL: 'https://status.spheron.network/api/v2/status.json',
    DEFAULT_DOMAIN: 'argoapp.io',
    IMAGE_NOT_FOUND:
      'https://ik.imagekit.io/argo/default-not-fount_RVbgyZDh5Q.png',
  },
  imageSize: {
    UPLOAD_IMAGE_SIZE: 5 * 1024 * 1024,
  },
  jsonSize: {
    UPLOAD_JSON_SIZE: 1 * 1024 * 1024,
  },
  web3: {
    PROJECT_ID: 'caff53d8ca5738df1e0f0de17aed90d9',
    CMC_KEY: '0c5b25a6-4d37-4836-8b43-a6c575667cdd',
    REMOVAL_VERIFYING_MESSAGE:
      "I'm the owner of this wallet and want to remove it from the organization.",
    ENABLE_VERIFYING_MESSAGE:
      "I'm the owner of this wallet and want to attach it to the organization.",
    WITHDRAWAL_VERIFYING_MESSAGE:
      'I hereby verify that I am the owner of the address.',
    TOKEN_SLIPPAGE: 0.01,
    matic: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0xE38E3EdA708F78c9fe75c80Eb4973fFa0bF43700',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0xd6db9ef51b821940ca9b52506222121301df042c',
      BICONOMY_KEY: '3alJF6kb1.13c26d0a-4514-4f22-8f1f-6db5ed8a854b',
      DESUB_GRAPHQL_URL:
        'https://api.thegraph.com/subgraphs/name/argoapp-live/payments-polygon-testnet',
      CHAIN_ID: 80001,
      TYPE: ChainType.TESTNET,
    },
    arbitrum: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0xA0c5ba7d9eccbE59000e8E67654CA5d867C6b401',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0x270d622149F46fFc72A9fD5A8cF92D1FdE5AD5E0',
      BICONOMY_KEY: 'MeM3kTNlG.fdba3616-3e9f-47cb-bf6e-5ec9913e3e6f',
      DESUB_GRAPHQL_URL:
        'https://api.thegraph.com/subgraphs/name/argoapp-live/payments-arbitrum-testnet',
      CHAIN_ID: 421613,
      TYPE: ChainType.TESTNET,
    },
    mantle: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0xb2E91e89E0493Ad4F3462BD8A4Ac59892eeEf380',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0x270d622149F46fFc72A9fD5A8cF92D1FdE5AD5E0',
      BICONOMY_KEY: 'MeM3kTNlG.fdba3616-3e9f-47cb-bf6e-5ec9913e3e6f',
      DESUB_GRAPHQL_URL: '',
      CHAIN_ID: 5001,
      TYPE: ChainType.TESTNET,
    },
    solana: {
      SUBSCRIPTION_CONTRACT_ADDRESS: '',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS: '',
      BICONOMY_KEY: '',
      DESUB_GRAPHQL_URL: '',
      CHAIN_ID: 10500,
      TYPE: ChainType.TESTNET,
      SOLANA_NETWORK_NAME: 'Solana',
      SOLANA_RPC: 'https://api.devnet.solana.com/',
      SOLANA_TREASURY: '2jmzh7h6KrNJSnzZTFE2MFPSeqMAnT2wPi4UqZFCjBfz',
      TOKEN_ADDRESS: 'So11111111111111111111111111111111111111112',
      SET_HARDCODE_AMOUNT: true,
      HARDCODE_AMOUNT_MULTIPLIER: 0.1,
      VERIFY_TRANSACTION_DELAY: 20000,
      AMOUNT_DECIMAL_PRECISION: 2,
    },
    bsc: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0xbf9afB0e70d2c77a63cF21eE43CD108373089C0f',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0x703E1fdcb950EC5B33E208628f928A316c9F828F',
      BICONOMY_KEY: 'MeM3kTNlG.fdba3616-3e9f-47cb-bf6e-5ec9913e3e6f',
      DESUB_GRAPHQL_URL:
        'https://api.thegraph.com/subgraphs/name/argoapp-live/payments-bsc-testnet',
      CHAIN_ID: 97,
      TYPE: ChainType.TESTNET,
    },
    optimism: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0x816413630c39780677E1584D4a6DbAFd76b161F7',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0x96Ca5955F25648FB44C5D772BAdB4313000C66Fe',
      BICONOMY_KEY: 'MeM3kTNlG.fdba3616-3e9f-47cb-bf6e-5ec9913e3e6f',
      DESUB_GRAPHQL_URL:
        'https://api.thegraph.com/subgraphs/name/argoapp-live/payments-optimism-testnet',
      CHAIN_ID: 420,
      TYPE: ChainType.TESTNET,
    },
    gnosis: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0x87c798cccDd9E5af60d615Ea5E31f3c968473b68',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0x5090d5E98F20fFb0dC5aeFd0dC752cD899Be25b0',
      BICONOMY_KEY: 'MeM3kTNlG.fdba3616-3e9f-47cb-bf6e-5ec9913e3e6f',
      DESUB_GRAPHQL_URL:
        'https://api.thegraph.com/subgraphs/name/argoapp-live/payments-gnosis-mainnet',
      CHAIN_ID: 100,
      TYPE: ChainType.TESTNET,
    },
    avalanche: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0x87B49A9b928b06AA5782cB7ec495da8c507CaADC',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0x96Ca5955F25648FB44C5D772BAdB4313000C66Fe',
      BICONOMY_KEY: '4uznnaqdF.825c9b1b-2359-46f1-a5b0-bf5474f99bff',
      DESUB_GRAPHQL_URL:
        'https://api.thegraph.com/subgraphs/name/argoapp-live/payments-avalanche-testnet',
      CHAIN_ID: 43113,
      TYPE: ChainType.TESTNET,
    },
    filecoin: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0xa3C6DFBee79d8f86494DBD7B5D943335bCF543f9',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0xA78b8e953B78b1D100cda10c7907b959edc6b021',
      BICONOMY_KEY: '4uznnaqdF.825c9b1b-2359-46f1-a5b0-bf5474f99bff',
      DESUB_GRAPHQL_URL: '',
      CHAIN_ID: 314159,
      TYPE: ChainType.TESTNET,
    },
    base: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0xB52329A11333462D192110357Be2da470B79B13e',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0x96Ca5955F25648FB44C5D772BAdB4313000C66Fe',
      BICONOMY_KEY: 'UNC5c83qw.55c0dea7-88cd-4bb0-9716-59f6c269f5b7',
      DESUB_GRAPHQL_URL:
        'https://api.studio.thegraph.com/query/10660/spheron-base-mainnet/version/latest',
      CHAIN_ID: 8453,
      TYPE: ChainType.MAINNET,
    },
    linea: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0x816413630c39780677E1584D4a6DbAFd76b161F7',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0x96Ca5955F25648FB44C5D772BAdB4313000C66Fe',
      BICONOMY_KEY: 'UNC5c83qw.55c0dea7-88cd-4bb0-9716-59f6c269f5b7',
      DESUB_GRAPHQL_URL: '',
      CHAIN_ID: 59144,
      TYPE: ChainType.MAINNET,
    },
    zksync: {
      SUBSCRIPTION_CONTRACT_ADDRESS:
        '0x8412Ab9512c574b483d65208C4bA77a86cC1a96E',
      SUBSCRIPTION_DATA_CONTRACT_ADDRESS:
        '0x863E4F8c6BC2F68d5902EBda655b98728B6C545A',
      BICONOMY_KEY: 'UNC5c83qw.55c0dea7-88cd-4bb0-9716-59f6c269f5b7',
      DESUB_GRAPHQL_URL:
        'https://api.studio.thegraph.com/query/10660/spheron-payment-zksync/version/latest',
      CHAIN_ID: 324,
      TYPE: ChainType.MAINNET,
    },
  },
  registry: {
    CONTRACT_ADDRESS: '0x5F94D696E079019f5c1C7Dd483c7aAfc8441C3E8',
    REGISTRY_SUBGRAPH_QUERY_URL:
      'https://api.thegraph.com/subgraphs/name/argoapp-live/secureon',
    REGISTRY_NETWORK: 137,
    REGISTRY_EXPLORER_LINK: 'https://polygonscan.com/address/',
  },
  nft: {
    SIGNING_MESSAGE: "I'm signing this message",
    CONTRACT_ADDRESS: '0xB4f93d4E0eE66F4bF850120776e47994bdC33DaA',
    NFT_GRAPHQL_URL:
      'https://api.thegraph.com/subgraphs/name/rekpero/spheronnftlaunch',
  },
  arweave: {
    GATEWAY_URL: 'https://arweave.net/',
  },
  skynet: {
    GATEWAY_URL: 'https://siasky.net/',
    DATA_DOMAIN: 'dev.argoapp.net',
  },
  ipfs: {
    GATEWAY_URL: 'dev.sphn.link',
  },
  akashToken: {
    microAKT: 1000000,
    decimalRound: 3,
    deployAktDebit: 5,
  },
  notification: {
    length: 5,
  },
  intercom: {
    APP_ID: 'bl9h9m1z',
  },
  planPrice: {
    DEPLOYMENT_ENV: 5,
  },
  dollarDecimalRound: {
    DOLLAR_DECIMAL_ROUND: 2,
  },
  stripe: {
    PUBLIC_KEY:
      // eslint-disable-next-line max-len
      'pk_test_51MQy3GSFJddRcWmbnVfL8GVDuVqYV6uP0eL5UMpWZNC4kTE9aH5FtUw1OUDuc6cUPl3xzvVMxjSanU8fsE327Hva00WlmShVHE',
  },
  referral: {
    MINIMUM_WITHDRAWAL_AMOUNT_IN_DOLLAR: 0.1,
    REGISTRATION_LINK: 'https://b4t4v7fj3cd.typeform.com/referral',
    RECAPTCHA_SITE_KEY: '6LcPoHsoAAAAAKSBuq6dKw0TnibY3MQNCWienNjw',
  },
  masterOrg: {
    LOADING_STEPS_DURATION: 6000,
  },
  compute: {
    MINIMUM_DEPOSIT: 15,
    DISABLE_NEW_INSTANCE: false,
  },
};

const config: {
  [name: string]: IConfig;
} = {
  local,
  development,
  tempdev,
  production,
};

export default config[NODE_ENV];
