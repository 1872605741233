import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  DropdownRow,
  Feedback,
  Modal,
  OptionType,
  SelectPaymentMethodCard,
  TextInput,
} from '@spheron/ui-library';
import { ReactComponent as CryptoPaymentIcon } from '@spheron/ui-library/dist/assets/crypto-social/Ethereum.svg';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModalShowRtk } from '../../../redux/compute/modal/modal.slice';
import { usePaymentMethod } from '../../../hooks/Compute/useSelectedPayment';
import { AppDispatch, RootState } from '../../../redux/compute/store';
import { PaymentMethod } from '../../../redux/compute/combined-state.interface';
import {
  getDecimalFromTokenAddress,
  isNonSubgraphChainId,
} from '../../../redux/compute/web3/web3.utils';
import { IOrganisationWallet } from '../../../redux/compute/web3/web3.interfaces';
import { createBonusReservationThunk } from '../../../redux/solana/solana.thunks';
import {
  getCurrentAppState,
  mapCurrentAppToSpecialization,
} from '../../../redux/compute/organisation/organisation.utils';
import {
  createBonusSubscriptionThunk,
  createCryptoBonusReservationThunk,
} from '../../../redux/compute/subscription/subscription.thunks';
import { ReservationType } from '../../../redux/solana/solana.utils';
import config from '../../../config';
import { getTokenLivePrice } from '../../../redux/compute/subscription/subscription.services';
import { ConnectionState } from '../../../redux/compute/stripe/stripe.interfaces';
import { ReactComponent as CreditCardPayment } from '../../../assets/compute/icons/credit-card.svg';
import { ReactComponent as Web3PaymentIcon } from '../../../assets/compute/icons/web3wallet.svg';
import { ReactComponent as SpheronWalletIcon } from '../../../assets/compute/global/spheron-wallet.svg';
import { addCreditCardThunk } from '../../../redux/compute/stripe/stripe.thunks';
import {
  setTempPaymentMethodIdRtk,
  toggleAddingPaymentMethodRtk,
} from '../../../redux/compute/organisation/organisation.slice';
import { setComputeTopupSelectedPaymentMethod } from '../../../redux/compute/subscription/subscription.slice';
import {
  IProcessor,
  IProcessorType,
} from '../../../redux/compute/subscription/subscription.interfaces';
import Styles from '../../../styles/compute/modal.module.scss';
import { NotificationType } from '../../../redux/compute/notification/notification.interfaces';
import { addNotificationRtk } from '../../../redux/compute/notification/notification.slice';
import ModalWrapper from './ModalWrapper';
import SpheronLoader from '../Loaders/spheron-loader';
import SelectPaymentMethod from '../Cards/select-payment-methods';

interface IProps {
  isOpen: boolean;
}

const TopUpBalance = ({ isOpen }: IProps) => {
  const dispatchRtk = useDispatch<AppDispatch>();
  const creditCards = useSelector(
    (state: RootState) => state.stripe.creditCards
  ).filter((card) => card.connectionState === ConnectionState.CONNECTED);

  const fetchingCreditCards = useSelector(
    (state: RootState) => state.stripe.fetchingCreditCards
  );

  const web3WalletsLoading = useSelector(
    (state: RootState) => state.web3.walletLoading
  );

  const web3Wallets = useSelector((state: RootState) => state.web3.wallets);

  const spheronWallet = useSelector(
    (state: RootState) => state.organisation.spheronWallet
  );
  const primaryPaymentMethodId = useSelector(
    (state: RootState) => state.organisation.primaryPaymentMethodId
  );
  const tempPaymentMethodId = useSelector(
    (state: RootState) => state.organisation.tempPaymentMethodId
  );
  const selectedOrgWallet = useSelector(
    (state: RootState) => state.organisation.selectedOrgWalletConfig
  );
  const selectedOrganisation = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation
  );
  const balance = useSelector((state: RootState) => state.web3.balance);
  const balanceLoading = useSelector(
    (state: RootState) => state.web3.escrowBalanceLoading
  );

  const allowedBonus: string[] = useSelector(
    (state: RootState) => state.subscription.allowedBonus
  );
  const currentApp = useSelector(
    (state: RootState) => state.organisation.selectedOrganisation.appType
  );

  const savingCreditCard: boolean = useSelector(
    (state: RootState) => state.stripe.savingCreditCard
  );

  const selectedOrgWalletConfig = useSelector(
    (state: RootState) => state.organisation.selectedOrgWalletConfig
  );

  const selectedOrgCreditCardConfig = useSelector(
    (state: RootState) => state.organisation.selectedOrgCreditCardConfig
  );

  const purchasingBonus = useSelector(
    (state: RootState) => state.subscription.purchasingBonus
  );

  const selectedPaymentMethod = useSelector(
    (state: RootState) => state.subscription.computeTopupSelectedPaymentMethod
  );

  const maxDepositedWallet = useSelector(
    (state: RootState) => state.web3.maxDepositedWallet
  );

  const addingPaymentMethod = useSelector(
    (state: RootState) => state.organisation.addingPaymentMethod
  );

  const { tempPaymentMethod } = usePaymentMethod(tempPaymentMethodId);
  const [topupAmount, setTopupAmount] = useState<number>();
  const [liveTokenPrice, setLiveTokenPrice] = useState<number>(1);
  const [liveTokenPriceLoading, setLiveTokenPriceLoading] = useState(true);
  const [showFullLoader, setShowFullLoader] = useState<boolean>(false);

  const closeModal = () => {
    dispatchRtk(
      toggleModalShowRtk({
        modalShow: false,
      })
    );
  };
  const isWeb3Wallet = useMemo(() => {
    return (
      selectedPaymentMethod === PaymentMethod.CRYPTO ||
      (!selectedPaymentMethod && selectedOrgWallet?.address)
    );
  }, [selectedOrgWallet?.address, selectedPaymentMethod]);

  const displayBalance = useMemo(() => {
    return !balanceLoading &&
      isWeb3Wallet &&
      isNonSubgraphChainId(
        Number(
          (tempPaymentMethod as IOrganisationWallet)?.details.networkDetails
            .chainId || selectedOrgWallet?.networkDetails?.chainId
        )
      )
      ? Number(balance?.amount).toFixed(4)
      : `${(
          Number(balance?.amount) /
          10 ** getDecimalFromTokenAddress(balance?.token || '')
        ).toFixed(4)}`;
  }, [
    balance?.amount,
    balance?.token,
    balanceLoading,
    isWeb3Wallet,
    selectedOrgWallet?.networkDetails?.chainId,
    tempPaymentMethod,
  ]);

  const isLessBalance =
    Number(Number(displayBalance) * liveTokenPrice) < Number(topupAmount);
  useEffect(() => {
    setLiveTokenPriceLoading(true);
    if (
      tempPaymentMethod &&
      tempPaymentMethod.paymentMethod === PaymentMethod.CRYPTO
    ) {
      getTokenLivePrice(
        (tempPaymentMethod as IOrganisationWallet).details.tokenDetails
          .symbol || 'USDT'
      ).then((res) => {
        setLiveTokenPrice(Number(res.price));
        setLiveTokenPriceLoading(false);
      });
    } else if (selectedOrgWallet._id) {
      getTokenLivePrice(selectedOrgWallet?.tokenDetails?.symbol || 'USDT').then(
        (res) => {
          setLiveTokenPrice(Number(res.price));
          setLiveTokenPriceLoading(false);
        }
      );
    }
  }, [
    selectedOrgWallet._id,
    selectedOrgWallet?.tokenDetails?.symbol,
    tempPaymentMethod,
  ]);

  // eslint-disable-next-line no-unused-vars
  const createBonus = () => {
    if (
      (selectedOrgWallet?.networkDetails?.chainName === 'solana' &&
        tempPaymentMethod === null) ||
      (tempPaymentMethod?.paymentMethod === PaymentMethod.CRYPTO &&
        (tempPaymentMethod as IOrganisationWallet).details.networkDetails
          .chainName === 'solana')
    ) {
      const payload = {
        organizationId: selectedOrganisation?._id,
        bonusParameterNames: allowedBonus,
        bonusParameterValues: [Number(topupAmount)],
        specialization: mapCurrentAppToSpecialization(
          getCurrentAppState(currentApp)
        ),
        renewValues: [false],
        processorType: IProcessorType.SMART_CONTRACT,
        processor: IProcessor.SOLANA,
        details: {
          amount: Number(
            (10 / liveTokenPrice).toFixed(
              config.web3.solana.AMOUNT_DECIMAL_PRECISION
            )
          ),
          walletAddress:
            (tempPaymentMethod as IOrganisationWallet)?.details?.address ||
            selectedOrgWallet?.address,
          walletId: tempPaymentMethodId || primaryPaymentMethodId || '',
        },
      };
      dispatchRtk(createBonusReservationThunk(payload));
      dispatchRtk(
        toggleModalShowRtk({
          modalShow: true,
          modalType: 'solanaPay',
          options: {
            walletAddress:
              (tempPaymentMethod as IOrganisationWallet)?.details?.address ||
              selectedOrgWallet?.address,
            amount: Number(
              (10 / liveTokenPrice).toFixed(
                config.web3.solana.AMOUNT_DECIMAL_PRECISION
              )
            ),
            type: ReservationType.Bonus,
          },
        })
      );
    } else
      dispatchRtk(
        createBonusSubscriptionThunk({
          walletId: tempPaymentMethodId || primaryPaymentMethodId || '',
          organizationId: selectedOrganisation?._id,
          bonusParameterNames: [allowedBonus[0]],
          bonusParameterValues: [topupAmount],
          specialization: mapCurrentAppToSpecialization(
            getCurrentAppState(currentApp)
          ),
          renewValues: [false],
        })
      );
  };

  const isTopUpDefined = typeof topupAmount !== 'undefined';
  const minAmount = config.compute.MINIMUM_DEPOSIT;
  const noTopupAmount = !isTopUpDefined || topupAmount < minAmount;

  const buttonText =
    isWeb3Wallet && isLessBalance && isTopUpDefined ? 'Deposit' : 'Purchase';

  const hasNoWallet =
    creditCards.length === 0 &&
    web3Wallets.length === 0 &&
    !spheronWallet &&
    !fetchingCreditCards &&
    !web3WalletsLoading;

  const depositDisabled = hasNoWallet
    ? savingCreditCard
    : !topupAmount ||
      (typeof topupAmount !== 'undefined' && topupAmount < minAmount);

  const isCreditCardPayment =
    (selectedOrgCreditCardConfig &&
      selectedOrgCreditCardConfig!._id!.length > 0 &&
      tempPaymentMethod === null) ||
    tempPaymentMethod?.paymentMethod === PaymentMethod.CREDITCARD;

  const isSpheronWalletPayment =
    tempPaymentMethod === null && selectedOrgWalletConfig?._id === ''
      ? false
      : !selectedOrgCreditCardConfig &&
        tempPaymentMethod?.paymentMethod !== PaymentMethod.SPHERONWALLET;

  const paymentSummary = topupAmount
    ? [
        {
          id: 1,
          heading: false,
          label: 'Monthly Total',
          value: <>${topupAmount?.toFixed(2)}</>,
          type: 'primary' as OptionType,
        },
        {
          id: 2,
          heading: false,
          label: 'Chosen Token',
          value: (
            <>
              {tempPaymentMethod &&
              tempPaymentMethod.paymentMethod === PaymentMethod.CRYPTO
                ? (tempPaymentMethod as IOrganisationWallet).details
                    .tokenDetails.symbol
                : selectedOrgWalletConfig?.tokenDetails?.symbol || 'USDT'}
            </>
          ),
          type: 'primary' as OptionType,
        },
        {
          id: 3,
          heading: false,
          label: 'Market Price',
          value: (
            <>
              {liveTokenPriceLoading ? (
                <Skeleton height={15} width={90} containerClassName="flex" />
              ) : (
                <>
                  {' '}
                  ${liveTokenPrice.toFixed(2)} USD /{' '}
                  {tempPaymentMethod &&
                  tempPaymentMethod.paymentMethod === PaymentMethod.CRYPTO
                    ? (tempPaymentMethod as IOrganisationWallet).details
                        .tokenDetails.symbol
                    : selectedOrgWalletConfig?.tokenDetails?.symbol || 'USDT'}
                </>
              )}
            </>
          ),
          type: 'primary' as OptionType,
        },
        {
          id: 4,
          heading: true,
          label: 'Total Value',
          value: (
            <>
              {(topupAmount / liveTokenPrice).toFixed(2)}{' '}
              {tempPaymentMethod &&
              tempPaymentMethod.paymentMethod === PaymentMethod.CRYPTO
                ? (tempPaymentMethod as IOrganisationWallet).details
                    .tokenDetails.symbol
                : selectedOrgWalletConfig?.tokenDetails?.symbol || 'USDT'}
            </>
          ),
          type: 'subheading' as OptionType,
        },
      ]
    : [];

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === '.') {
      event.preventDefault();
    }
  };

  // Infer better type from usage
  const handleAmountChange = (value: any) => {
    const parsedValue = Number(
      String(Math.floor(value)).replace(/[^0-9]/g, '')
    );
    setTopupAmount(parsedValue);
  };

  const primaryPaymentMethodType = useMemo(() => {
    if (primaryPaymentMethodId) {
      const creditCard = creditCards.find(
        (creditCard) => creditCard._id === primaryPaymentMethodId
      );
      const web3Wallet = web3Wallets.find(
        (wallet) => wallet._id === primaryPaymentMethodId
      );
      if (creditCard) {
        return PaymentMethod.CREDITCARD;
      }
      if (web3Wallet) {
        return PaymentMethod.CRYPTO;
      }
      if (spheronWallet?._id === primaryPaymentMethodId) {
        return PaymentMethod.SPHERONWALLET;
      }
      return null;
    }
    return null;
  }, [primaryPaymentMethodId, creditCards, web3Wallets, spheronWallet?._id]);

  const handleCryptoIntegrationPurchase = () => {
    setShowFullLoader(true);
    const payload = {
      organizationId: selectedOrganisation._id,
      bonusParameterNames: ['BONUS_CLUSTER_AKT'],
      bonusParameterValues: [Number(topupAmount)],
      specialization: mapCurrentAppToSpecialization(
        getCurrentAppState(currentApp)
      ),
      processorType: IProcessorType.CRYTPO_INTEGRATION,
      processor: IProcessor.COPPERX,
      details: {
        successUrl: `${window.location.origin}/crypto-payment-callback?copperx_success=true`,
        cancelUrl: `${window.location.origin}/crypto-payment-callback?copperx_success=false`,
      },
    };
    dispatchRtk(createCryptoBonusReservationThunk(payload))
      .then((value: any) => {
        if (value.payload.error) {
          setShowFullLoader(false);
          dispatchRtk(
            addNotificationRtk({
              message: value.payload.message,
              timestamp: Date.now(),
              type: NotificationType.Error,
            })
          );
          return;
        }
        const cryptoPaymentWindow: any = window.open(
          value.payload.additionalInformation.redirectUrl
        );
        const cryptoPaymentWindowPollTimer = window.setInterval(() => {
          if (cryptoPaymentWindow.closed) {
            window.clearInterval(cryptoPaymentWindowPollTimer);
            setShowFullLoader(false);
          }
        }, 1000);
      })
      .catch(() => {
        setShowFullLoader(false);
      });
  };

  const [topupStep, setTopupStep] = useState<number>(1);

  const paymentOptions = [
    {
      id: PaymentMethod.CRYTPO_INTEGRATION,
      title: 'Crypto Payment',
      icon: <CryptoPaymentIcon className="w-5 h-5" />,
      handleClick: () => null,
      isRecommended: true,
      visible: true,
    },
    {
      id: PaymentMethod.CREDITCARD,
      title: 'Credit / Debit Card',
      icon: <CreditCardPayment className="w-5 h-5" />,
      handleClick: () => {
        if (creditCards.length > 0) {
          if (primaryPaymentMethodType !== PaymentMethod.CREDITCARD) {
            dispatchRtk(setTempPaymentMethodIdRtk(creditCards[0]._id));
          } else {
            dispatchRtk(setTempPaymentMethodIdRtk(primaryPaymentMethodId));
          }
        } else {
          setShowFullLoader(true);
          dispatchRtk(toggleAddingPaymentMethodRtk(true));
          dispatchRtk(
            addCreditCardThunk({
              organizationId: selectedOrganisation._id,
              details: {
                cardPaymentMethodType: 'card',
                cardService: 'stripe',
              },
              paymentMethod: PaymentMethod.CREDITCARD,
            })
          ).finally(() => {
            setShowFullLoader(false);
          });
        }
      },
      isRecommended: false,
    },
    // Added check if deposited balance > $15
    ...(maxDepositedWallet && Number(maxDepositedWallet?.balance) >= 15
      ? [
          {
            id: PaymentMethod.CRYPTO,
            title: 'Web3 Payment',
            icon: <Web3PaymentIcon className="dark:text-white w-5 h-5" />,
            handleClick: () => {
              if (web3Wallets.length > 0) {
                if (primaryPaymentMethodType !== PaymentMethod.CRYPTO) {
                  dispatchRtk(setTempPaymentMethodIdRtk(web3Wallets[0]._id));
                } else {
                  dispatchRtk(
                    setTempPaymentMethodIdRtk(primaryPaymentMethodId)
                  );
                }
              } else {
                dispatchRtk(
                  toggleModalShowRtk({
                    modalType: 'connectWalletParameters',
                    modalShow: true,
                  })
                );
              }
            },
            isRecommended: false,
          },
        ]
      : []),

    {
      id: PaymentMethod.SPHERONWALLET,
      title: 'Spheron Credit',
      icon: <SpheronWalletIcon className="dark:text-white w-5 h-5" />,
      handleClick: () => {
        if (spheronWallet) {
          if (primaryPaymentMethodType !== PaymentMethod.SPHERONWALLET) {
            dispatchRtk(setTempPaymentMethodIdRtk(spheronWallet._id));
          } else {
            dispatchRtk(setTempPaymentMethodIdRtk(primaryPaymentMethodId));
          }
        }
      },
      isRecommended: false,
    },
  ];

  const showCTA =
    (selectedPaymentMethod === PaymentMethod.CRYPTO &&
      web3Wallets.length > 0) ||
    (selectedPaymentMethod === PaymentMethod.CREDITCARD &&
      creditCards.length > 0) ||
    (selectedPaymentMethod === PaymentMethod.SPHERONWALLET && spheronWallet) ||
    !noTopupAmount;

  const handleSelectPaymentMethod = (paymentOption: any) => {
    paymentOption.handleClick();
    dispatchRtk(setComputeTopupSelectedPaymentMethod(paymentOption.id));
    setTopupStep(2);
  };

  useEffect(() => {
    if (
      selectedPaymentMethod === PaymentMethod.CREDITCARD &&
      !addingPaymentMethod &&
      creditCards.filter(
        (card) => card.connectionState === ConnectionState.CONNECTED
      ).length > 0
    ) {
      setShowFullLoader(false);
      setTopupStep(2);
    } else if (
      selectedPaymentMethod === PaymentMethod.CREDITCARD &&
      addingPaymentMethod &&
      creditCards.filter(
        (card) => card.connectionState === ConnectionState.CONNECTED
      ).length === 0
    )
      setShowFullLoader(true);
  }, [addingPaymentMethod, creditCards, selectedPaymentMethod]);

  const handlePrimaryButtonClick = () => {
    if (isWeb3Wallet && isLessBalance) {
      dispatchRtk(
        toggleModalShowRtk({
          modalShow: true,
          modalType: 'funds',
          options: {
            type: 'deposit',
            walletId: tempPaymentMethodId || primaryPaymentMethodId,
          },
        })
      );
      return;
    }
    if (selectedPaymentMethod === PaymentMethod.CRYTPO_INTEGRATION) {
      handleCryptoIntegrationPurchase();
      return;
    }
    createBonus();
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={() => {
        dispatchRtk(toggleModalShowRtk({ modalShow: false }));
      }}
      classname={`${Styles.modal__outer__con} modal fixed inset-0 overflow-y-auto`}
    >
      <div className={`${Styles.modal__inner__content} !w-[530px]`}>
        {showFullLoader ? (
          <div className="h-[460px] flex items-center justify-center dark:bg-dark-base-bg bg-base-bg rounded-xl">
            <SpheronLoader height={90} width={90} />
          </div>
        ) : (
          <Modal
            header
            title="Top up Your Balance"
            subtitle=""
            modalContent={
              <div className="flex flex-col gap-4 w-full">
                <div className="flex flex-col space-y-3.5">
                  <div>
                    {topupStep === 1 && (
                      <div className="flex flex-col gap-y-3">
                        <span
                          className="text-[14px] leading-4 font-semibold
                                  text-base-heading-text-color dark:text-dark-base-heading-text-color"
                        >
                          Select Payment Method
                        </span>
                        {paymentOptions.map((paymentOption) => {
                          if (
                            paymentOption.id === PaymentMethod.SPHERONWALLET &&
                            !spheronWallet
                          ) {
                            return null;
                          }
                          return (
                            <SelectPaymentMethodCard
                              key={paymentOption.id}
                              title={paymentOption.title}
                              icon={paymentOption.icon}
                              recommended={paymentOption.isRecommended}
                              disabled={false}
                              handleClick={() =>
                                handleSelectPaymentMethod(paymentOption)
                              }
                            />
                          );
                        })}
                        <span className="mt-3 text-[11px] leading-3 text-cards-text dark:text-dark-cards-text">
                          <span className="text-action-error-default">*</span>{' '}
                          Amount deposited is non-refundable.
                        </span>
                      </div>
                    )}
                    {topupStep === 2 && (
                      <>
                        <TextInput
                          value={String(topupAmount) || ''}
                          inputSize="compact"
                          placeholder="e.g. $15"
                          // eslint-disable-next-line max-len
                          helpText={`Compute services require a minimum credit of $${minAmount}`}
                          type="number"
                          error={noTopupAmount}
                          min={0}
                          onKeyDown={(event: unknown) =>
                            handleKeyDown(event as any)
                          }
                          label="Enter amount (USD)"
                          onChange={(value) => handleAmountChange(value)}
                        />
                      </>
                    )}
                    {selectedPaymentMethod && !noTopupAmount && (
                      <>
                        <div>
                          <div className="flex flex-col gap-y-3">
                            {hasNoWallet ? (
                              <Feedback
                                feedbackType="error"
                                title="No method attached"
                                showClose={false}
                              />
                            ) : (
                              <>
                                {web3WalletsLoading || fetchingCreditCards ? (
                                  <Skeleton
                                    width="100%"
                                    height={106}
                                    containerClassName="w-full"
                                    duration={2}
                                  />
                                ) : (
                                  <SelectPaymentMethod
                                    tempPaymentMethod={tempPaymentMethod}
                                    heading={null}
                                  />
                                )}
                                {isWeb3Wallet &&
                                  !balanceLoading &&
                                  isLessBalance &&
                                  isTopUpDefined && (
                                    <Feedback
                                      feedbackType="error"
                                      title="Low Funds! Please deposit on your wallet."
                                      showClose={false}
                                    />
                                  )}
                              </>
                            )}
                          </div>
                        </div>
                        {(!isCreditCardPayment ||
                          isSpheronWalletPayment ||
                          !noTopupAmount) &&
                          selectedPaymentMethod === PaymentMethod.CRYPTO && (
                            <div
                              className="mt-3 bg-base-bg dark:bg-dark-base-bg
                                  border border-base-border dark:border-dark-base-border rounded-lg"
                            >
                              {paymentSummary?.map((payment) => (
                                <>
                                  <DropdownRow
                                    key={payment.id}
                                    option={{
                                      optionType: payment.type,
                                      id: 1,
                                      label: payment.label,
                                      value: payment.label,
                                      dropdownRowClassname: `!cursor-default ${
                                        payment.type === 'subheading'
                                          ? ''
                                          : '!bg-transparent'
                                      }`,
                                      rightIcon: (
                                        <span
                                          className={
                                            payment.type === 'subheading'
                                              ? 'text-3 leading-3 uppercase font-600'
                                              : 'text-[11px] font-400 leading-3'
                                          }
                                        >
                                          {payment.value}
                                        </span>
                                      ),
                                    }}
                                    dropdownType="default"
                                    handleSelected={() => null}
                                    selectedOption={undefined}
                                  />
                                </>
                              ))}
                            </div>
                          )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            }
            requiredMessage={(topupAmount || 0) >= minAmount}
            requiredMessageContent={
              <span className="text-[11px] leading-3 text-cards-text dark:text-dark-cards-text">
                <span className="text-action-error-default">*</span> Amount
                deposited is non-refundable.
              </span>
            }
            headerContent={<>Top up Your Balance</>}
            primaryButtonContent={buttonText}
            secondaryButtonContent="Cancel"
            primaryButton={!!showCTA}
            footer={
              !!showCTA &&
              !!selectedPaymentMethod &&
              (topupAmount || 0) >= minAmount
            }
            primaryButtonLoading={savingCreditCard || purchasingBonus}
            primaryButtonDisabled={depositDisabled}
            secondaryButtonDisabled={savingCreditCard || purchasingBonus}
            secondaryButton
            onPrimaryButtonClick={() => handlePrimaryButtonClick()}
            onSecondaryButtonClick={closeModal}
          />
        )}
      </div>
    </ModalWrapper>
  );
};

export default TopUpBalance;
