/* eslint-disable max-len */
import { Badge, SpheronApp } from '@spheron/ui-library';
import { ReactComponent as ConsoleIcon } from '@spheron/ui-library/dist/assets/compute/console.svg';
import { ReactComponent as CloudAppIcon } from '@spheron/ui-library/dist/assets/compute/cloud-app.svg';
import { ReactComponent as ProviderEarningIcon } from '@spheron/ui-library/dist/assets/compute/provider-earning.svg';
import { ReactComponent as FizzIcon } from '@spheron/ui-library/dist/assets/compute/multi-service.svg';
import { ReactComponent as SpheronLogo } from '@spheron/ui-library/dist/assets/spheron-specific/spheron-logo.svg';
import React from 'react';
import { useSelector } from 'react-redux';
import { DISABLED_APPS } from '../../../../config';
import { RootState } from '../../../../redux/rtk-store';

const ChooseSpheronApp = () => {
  const user = useSelector((state: RootState) => state.user.user);

  const selectedOrg =
    user?.organizations.find((org) => org._id === user.defaultOrganization) ||
    user?.organizations[0];

  const spheronApps = [
    {
      id: 1,
      name: 'Console',
      helpText:
        'Deploy and manage your applications with ease using our YAML CLI.',
      icon: <ConsoleIcon className="w-8 h-8" />,
      url: 'https://console.spheron.network',
      disabled: JSON.parse(DISABLED_APPS).apps.find(
        (app: string) => app === 'console'
      ),
    },
    {
      id: 2,
      name: 'Provider',
      helpText:
        'Earn rewards by providing and managing your compute resources.',
      icon: <ProviderEarningIcon className="w-8 h-8" />,
      url: 'https://provider.spheron.network',
      disabled: JSON.parse(DISABLED_APPS).apps.find(
        (app: string) => app === 'provider'
      ),
    },
    {
      id: 3,
      name: 'Fizz Node',
      helpText:
        'Monetize your resources effortlessly with decentralized network.',
      icon: <FizzIcon className="w-8 h-8" />,
      url: 'https://fizz.spheron.network',
      disabled: JSON.parse(DISABLED_APPS).apps.find(
        (app: string) => app === 'fizz'
      ),
    },
    {
      id: 4,
      name: 'Cloud',
      helpText: 'Seamlessly deploy and manage your cloud infrastructure.',
      icon: <CloudAppIcon className="w-8 h-8" />,
      url:
        user?.organizations.length === 0
          ? `/onboarding`
          : `/${selectedOrg?.compute?.profile?.username}/dashboard`,
      disabled: JSON.parse(DISABLED_APPS).apps.find(
        (app: string) => app === 'cloud'
      ),
      disabledText: 'Unavailable',
    },
  ];

  return (
    <div className="flex flex-col gap-y-11 items-center justify-start md:justify-center w-full h-[calc(100vh-72px)] overflow-auto py-12 md:py-0">
      <h1
        className="px-4 md:px-0 text-[30px] md:text-[36px] leading-11 font-bold 
      text-base-heading-text-color dark:text-dark-base-heading-text-color"
      >
        Welcome to Spheron Network
      </h1>
      <div className="flex flex-col gap-y-4 px-4 md:px-0">
        {spheronApps.map((app) => (
          <div className="relative w-full" key={app.id}>
            <SpheronApp
              onClick={
                app.disabled ? () => {} : () => window.open(app.url, '_self')
              }
              heading={
                <div className="flex items-center justify-start gap-x-2">
                  <SpheronLogo className="w-8 h-10 text-logo-primary dark:text-dark-logo-primary" />
                  <h2 className="font-semibold text-xl">{app.name}</h2>
                  {app.disabledText && (
                    <Badge
                      solid={false}
                      size="medium"
                      badgeType="info"
                      text={app.disabledText}
                    />
                  )}
                </div>
              }
              helpText={app.helpText}
              icon={app.icon}
            />
            {/* Do not Add this logic to disable the above component when migratiing, instead update UI Lib */}
            {app.disabled ? (
              <div
                className="absolute top-0 left-0 z-99 w-full h-full rounded-lg
              cursor-not-allowed bg-base-fg dark:bg-dark-base-fg opacity-50"
              />
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChooseSpheronApp;
