import React from 'react';
import SpheronLoader from './spheron-loader';

function Loader() {
  return (
    <div
      className="h-screen w-full flex items-center 
    justify-center dark:bg-dark-base-bg bg-base-bg"
    >
      <SpheronLoader height={150} width={150} />
    </div>
  );
}

export default Loader;
